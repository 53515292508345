import * as z from 'zod'

// Basic values

export const TranslationValue = z.string()

export const TranslatedValueSchema = z.object({
  de: TranslationValue,
  ee: TranslationValue,
  en: TranslationValue,
  lt: TranslationValue,
  lv: TranslationValue,
  ru: TranslationValue
})
export type TranslatedValue = z.infer<typeof TranslatedValueSchema>

// Translation IDs

export const TranslationValueId = z.number().nonnegative()
export type TranslationValueId = z.infer<typeof TranslationValueId>

export const CountryTranslationIdSchema = TranslationValueId
export type CountryTranslationId = z.infer<typeof CountryTranslationIdSchema>

export const VehicleVariantTranslationIdSchema = TranslationValueId
export type VehicleVariantTranslationId = z.infer<typeof VehicleVariantTranslationIdSchema>

export const VehicleColorTranslationIdSchema = TranslationValueId
export type VehicleColorTranslationId = z.infer<typeof VehicleColorTranslationIdSchema>

export const WheelDriveTranslationIdSchema = TranslationValueId
export type WheelDriveTranslationId = z.infer<typeof WheelDriveTranslationIdSchema>

export const TransmissionTypeTranslationIdSchema = TranslationValueId
export type TransmissionTypeTranslationId = z.infer<typeof TransmissionTypeTranslationIdSchema>

export const FuelTypeTranslationIdSchema = TranslationValueId
export type FuelTypeTranslationId = z.infer<typeof FuelTypeTranslationIdSchema>

export const VehicleOptionGroupTranslationIdSchema = TranslationValueId
export type VehicleOptionGroupTranslationId = z.infer<typeof VehicleOptionGroupTranslationIdSchema>

// Translated entities

// TODO: this must be a polymorphic type with: T extends TranslationValueId
export const TranslatedEntitySchema = TranslatedValueSchema.extend({
  id: TranslationValueId
})
export type TranslatedEntity = z.infer<typeof TranslatedEntitySchema>

export const TranslatedCountrySchema = TranslatedValueSchema.extend({
  id: CountryTranslationIdSchema,
  type: z.literal('COUNTRIES')
})
export type TranslatedCountry = z.infer<typeof TranslatedCountrySchema>

export const TranslatedVariantSchema = TranslatedValueSchema.extend({
  id: VehicleVariantTranslationIdSchema,
  type: z.literal('VARIANTS')
})
export type TranslatedVariant = z.infer<typeof TranslatedVariantSchema>

export const TranslatedColorSchema = TranslatedValueSchema.extend({
  id: VehicleColorTranslationIdSchema,
  type: z.literal('COLORS')
})
export type TranslatedColor = z.infer<typeof TranslatedColorSchema>

export const TranslatedWheelDriveSchema = TranslatedValueSchema.extend({
  id: WheelDriveTranslationIdSchema,
  type: z.literal('DRIVES')
})
export type TranslatedWheelDrive = z.infer<typeof TranslatedWheelDriveSchema>

export const TranslatedFuelTypeSchema = TranslatedValueSchema.extend({
  id: FuelTypeTranslationIdSchema,
  type: z.literal('FUELS')
})
export type TranslatedFuelType = z.infer<typeof TranslatedFuelTypeSchema>

export const TranslatedTransmissionTypeSchema = TranslatedValueSchema.extend({
  id: TransmissionTypeTranslationIdSchema,
  type: z.literal('TRANSMISSIONS')
})
export type TranslatedTransmissionType = z.infer<typeof TranslatedTransmissionTypeSchema>

export const TranslatedOptionGroupSchema = TranslatedValueSchema.extend({
  id: VehicleOptionGroupTranslationIdSchema,
  type: z.literal('OPTION_GROUPS')
})
export type TranslatedOptionGroup = z.infer<typeof TranslatedOptionGroupSchema>

// Translation values

export const VehicleColorTranslationValueSchema = z.object({
  type: z.literal('COLORS'),
  value: VehicleColorTranslationIdSchema
})
export type VehicleColorTranslationValue = z.infer<typeof VehicleColorTranslationValueSchema>

export const FuelTypeTranslationValueSchema = z.object({
  type: z.literal('FUELS'),
  value: FuelTypeTranslationIdSchema
})
export type FuelTypeTranslationValue = z.infer<typeof FuelTypeTranslationValueSchema>

export const TransmissionTypeTranslationValueSchema = z.object({
  type: z.literal('TRANSMISSIONS'),
  value: TransmissionTypeTranslationIdSchema
})
export type TransmissionTypeTranslationValue = z.infer<typeof TransmissionTypeTranslationValueSchema>

export const VehicleVariantTranslationValueSchema = z.object({
  type: z.literal('VARIANTS'),
  value: VehicleVariantTranslationIdSchema
})
export type VehicleVariantTranslationValue = z.infer<typeof VehicleVariantTranslationValueSchema>

export const WheelDriveTranslationValueSchema = z.object({
  type: z.literal('DRIVES'),
  value: WheelDriveTranslationIdSchema
})
export type WheelDriveTranslationValue = z.infer<typeof WheelDriveTranslationValueSchema>

export const VehicleOptionGroupTranslationValueSchema = z.object({
  type: z.literal('OPTION_GROUPS'),
  value: VehicleOptionGroupTranslationIdSchema
})
export type VehicleOptionGroupTranslationValue = z.infer<typeof VehicleOptionGroupTranslationValueSchema>
