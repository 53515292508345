import * as z from 'zod'
import { LocalDateSchema, NumericValuesRangeSchema } from '@digital-magic/zod-common-schemas'
import {
  EngineDisplacementSchema,
  EnginePowerSchema,
  EngineVolumeSchema,
  FuelConsumptionSchema,
  MileageSchema,
  OrderedImageSchema,
  PriceSchema,
  UrlStringSchema,
  VehicleIdSchema,
  VehicleMakeSchema,
  VehicleModelExtraSchema,
  VehicleModelSchema,
  VehicleRegistrationNumberSchema,
  VehicleYearSchema,
  VinCodeSchema
} from '@api/endpoints'
import {
  FuelTypeTranslationValueSchema,
  TranslatedValueSchema,
  TransmissionTypeTranslationIdSchema,
  TransmissionTypeTranslationValueSchema,
  VehicleColorTranslationValueSchema,
  VehicleOptionGroupTranslationValueSchema,
  VehicleVariantTranslationValueSchema,
  WheelDriveTranslationIdSchema,
  WheelDriveTranslationValueSchema
} from '@api/endpoints/translations'

export const VehicleCountSchema = z.number()
export type VehicleCount = z.infer<typeof VehicleCountSchema>

export const BodyTypeEnumSchema = z.enum([
  'TRAILER',
  'CARAVAN',
  'CABRIOLET',
  'COMMERCIAL_VEHICLE',
  'COUPE',
  'HATCHBACK',
  'MINIVAN',
  'MOTO',
  'PICKUP',
  'SUV',
  'SEDAN',
  'TOURING',
  'JET'
])
export type BodyTypeEnum = z.infer<typeof BodyTypeEnumSchema>

export const FuelTypeEnumSchema = z.enum(['PETROL', 'DIESEL', 'ELECTRIC', 'HYBRID', 'PLUGIN', 'OTHER'])
export type FuelTypeEnum = z.infer<typeof FuelTypeEnumSchema>

export const WarrantyTypeEnumSchema = z.enum([
  'FACTORY',
  'CARPROF_STANDARD',
  'CARPROF_STANDARD_PLUS',
  'CARPROF_PREMIUM',
  'NONE'
])
export type WarrantyTypeEnum = z.infer<typeof WarrantyTypeEnumSchema>

export const VehicleSortByEnumSchema = z.enum(['YEAR', 'PRICE', 'MILEAGE', 'NEWLY_ADDED'])
export type VehicleSortByEnum = z.infer<typeof VehicleSortByEnumSchema>

export const VehicleSortDirectionEnumSchema = z.enum(['ASC', 'DESC'])
export type VehicleSortDirectionEnum = z.infer<typeof VehicleSortDirectionEnumSchema>

export const VehiclesSearchQueryParamsSchema = z.object({
  variants: BodyTypeEnumSchema.array(),
  make: VehicleMakeSchema.optional(),
  model: VehicleModelSchema.optional(),
  fuels: FuelTypeEnumSchema.array(),
  transmissions: TransmissionTypeTranslationIdSchema.array(),
  wheelDrives: WheelDriveTranslationIdSchema.array(),
  price: NumericValuesRangeSchema.optional(),
  powerInKw: NumericValuesRangeSchema.optional(),
  year: NumericValuesRangeSchema.optional(),
  mileage: NumericValuesRangeSchema.optional(),
  engineDisplacementRange: NumericValuesRangeSchema.optional(),
  // warrantyTypeId: WarrantyTypeEnumSchema.optional(),
  sortBy: VehicleSortByEnumSchema.optional()
})
export type VehiclesSearchQueryParams = z.infer<typeof VehiclesSearchQueryParamsSchema>

export const VehicleOptionResponseSchema = z.object({
  itemResponses: TranslatedValueSchema.array(),
  optionGroup: VehicleOptionGroupTranslationValueSchema
})
export type VehicleOptionResponse = z.infer<typeof VehicleOptionResponseSchema>

export const VehicleImageSchema = OrderedImageSchema
export type VehicleImage = z.infer<typeof VehicleImageSchema>

export const VehicleMainDataSchema = z.object({
  color: VehicleColorTranslationValueSchema.optional(),
  engineDisplacement: EngineDisplacementSchema.optional(),
  fuel: FuelTypeTranslationValueSchema.optional(),
  fuelGroup: FuelTypeEnumSchema.optional(),
  fuelConsumptionAverage: FuelConsumptionSchema.optional(),
  fuelConsumptionCity: FuelConsumptionSchema.optional(),
  fuelConsumptionHighway: FuelConsumptionSchema.optional(),
  mileage: MileageSchema.optional(),
  powerInKw: EnginePowerSchema.optional(),
  registrationNumber: VehicleRegistrationNumberSchema.optional(),
  transmission: TransmissionTypeTranslationValueSchema.optional(),
  variant: VehicleVariantTranslationValueSchema.optional(),
  wheelDrive: WheelDriveTranslationValueSchema.optional(),
  year: VehicleYearSchema.optional(),
  vin: VinCodeSchema.optional()
})
export type VehicleMainData = z.infer<typeof VehicleMainDataSchema>

export const VehicleBaseSchema = z.object({
  id: VehicleIdSchema,
  carWarranty: WarrantyTypeEnumSchema.optional(),
  discountedPrice: PriceSchema.optional(),
  fullPrice: PriceSchema,
  isBoughtNewFromEstonia: z.boolean().optional(),
  isServiceBookPresent: z.boolean().optional(),
  make: VehicleMakeSchema,
  model: VehicleModelSchema,
  modelExtra: VehicleModelExtraSchema.optional(),
  monthlyPaymentFrom: PriceSchema.optional(),
  isVatIncluded: z.boolean().optional()
})
export type VehicleBase = z.infer<typeof VehicleBaseSchema>

export const VehicleFullSchema = VehicleBaseSchema.extend({
  isAvailable: z.boolean(),
  attachments: VehicleImageSchema.array(),
  image360Url: UrlStringSchema.optional(),
  videoUrl: UrlStringSchema.optional(),
  mainData: VehicleMainDataSchema,
  warrantyUntil: z.string().optional()
})
export type VehicleFull = z.infer<typeof VehicleFullSchema>

export const VehicleShortSchema = VehicleBaseSchema.extend({
  fuel: FuelTypeEnumSchema.optional(),
  mileage: MileageSchema.optional(),
  wheelDrive: WheelDriveTranslationValueSchema.optional(),
  transmission: TransmissionTypeTranslationValueSchema.optional(),
  year: VehicleYearSchema.optional(),
  fullSizeImageUrl: UrlStringSchema.optional(),
  thumbnailImageUrl: UrlStringSchema.optional()
})
export type VehicleShort = z.infer<typeof VehicleShortSchema>

export const VehicleListResponseSchema = z.object({
  currentPage: z.number(),
  totalPages: z.number(),
  content: VehicleShortSchema.array()
})
export type VehicleListResponse = z.infer<typeof VehicleListResponseSchema>

export const VehicleByRegNumberSchema = z.object({
  id: VehicleIdSchema,
  discountedPrice: PriceSchema.optional(),
  fullPrice: PriceSchema,
  make: VehicleMakeSchema,
  model: VehicleModelSchema,
  modelExtra: VehicleModelSchema.optional(),
  monthlyPaymentFrom: PriceSchema.optional(),
  fullSizeImageUrl: UrlStringSchema.optional(),
  thumbnailImageUrl: UrlStringSchema.optional(),
  registrationNumber: VehicleRegistrationNumberSchema,
  year: VehicleYearSchema.optional(),
  isVatIncluded: z.boolean().optional()
})
export type VehicleByRegNumber = z.infer<typeof VehicleByRegNumberSchema>

export const VehicleMntSearchResponseSchema = z.object({
  engine: FuelTypeTranslationValueSchema.optional(),
  engineVolCm3: EngineVolumeSchema.optional(),
  engineKw: EnginePowerSchema.optional(),
  firstRegYear: VehicleYearSchema.optional(),
  mark: VehicleMakeSchema,
  model: VehicleModelSchema,
  nextInspectionDate: LocalDateSchema.optional(),
  transmission: TransmissionTypeTranslationValueSchema.optional(),
  registrationNumber: VehicleRegistrationNumberSchema.optional()
})
export type VehicleMntSearchResponse = z.infer<typeof VehicleMntSearchResponseSchema>
